<template>
  <div class="wrapper">
    
  </div>
</template>
<script>

export default {
  name: 'FTPKlient',
  components: {
  },
  data() {
  }
}

window.location.replace("https://github.com/LightCap1337/FTPClient");
</script>

<style scoped>
</style>